import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    template: '',
    selector: 'dxrd-callbacks'
})
export class DxReportDesignerCallbacksComponent {
    @Output() CustomizeElements = new EventEmitter<any>();
    @Output() ExitDesigner = new EventEmitter<any>();
    @Output() ReportSaving = new EventEmitter<any>();
    @Output() ReportSaved = new EventEmitter<any>();
    @Output() ReportOpened = new EventEmitter<any>();
    @Output() ReportOpening = new EventEmitter<any>();
    @Output() TabChanged = new EventEmitter<any>();
    @Output() ComponentAdded = new EventEmitter<any>();
    @Output() CustomizeParameterEditors = new EventEmitter<any>();
    @Output() CustomizeSaveDialog = new EventEmitter<any>();
    @Output() CustomizeSaveAsDialog = new EventEmitter<any>();
    @Output() CustomizeOpenDialog = new EventEmitter<any>();
    @Output() CustomizeToolbox = new EventEmitter<any>();
    @Output() CustomizeFieldListActions = new EventEmitter<any>();
    @Output() CustomizeMenuActions = new EventEmitter<any>();
    @Output() CustomizeLocalization = new EventEmitter<any>();
    @Output() BeforeRender = new EventEmitter<any>();
    @Output() OnServerError = new EventEmitter<any>();
    @Output() CustomizeWizard = new EventEmitter<any>();
    
    @Output() PreviewClick = new EventEmitter<any>();
    @Output() PreviewCustomizeElements = new EventEmitter<any>();
    @Output() PreviewCustomizeMenuActions = new EventEmitter<any>();
    @Output() PreviewEditingFieldChanged = new EventEmitter<any>();
    @Output() PreviewDocumentReady = new EventEmitter<any>();
    @Output() PreviewParametersReset = new EventEmitter<any>();
    @Output() PreviewCustomizeExportOptions = new EventEmitter<any>();
    @Output() PreviewParametersSubmitted = new EventEmitter<any>();
}
