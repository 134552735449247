import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    template: '',
    selector: 'dxrv-callbacks'
})
export class DxReportViewerCallbacksComponent {
    @Output() CustomizeElements = new EventEmitter<any>();
    @Output() PreviewClick = new EventEmitter<any>();
    @Output() EditingFieldChanged = new EventEmitter<any>();
    @Output() DocumentReady = new EventEmitter<any>();
    @Output() CustomizeExportOptions = new EventEmitter<any>();
    @Output() CustomizeParameterEditors = new EventEmitter<any>();
    @Output() CustomizeParameterLookUpSource = new EventEmitter<any>();
    @Output() ParametersReset = new EventEmitter<any>();
    @Output() ParametersSubmitted = new EventEmitter<any>();
    @Output() CustomizeMenuActions = new EventEmitter<any>();
    @Output() CustomizeLocalization = new EventEmitter<any>();
    @Output() BeforeRender = new EventEmitter<any>();
    @Output() OnServerError = new EventEmitter<any>();
}
