import { Component, Input, Renderer2, ContentChild, Inject, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import  * as ko from 'knockout';
import DxReportDesigner from "devexpress-reporting/dx-reportdesigner";
import { DxReportingComponent } from '../dx-reporting-component';

import { DxReportDesignerRequestOptionsComponent } from './dxrd-request-options';
import { DxReportDesignerCallbacksComponent } from './dxrd-callbacks';

@Component({
  selector: 'dx-report-designer',
  encapsulation: ViewEncapsulation.None,
  template: `<div #control [ngClass]="cssClass" [ngStyle]="{'width': width, 'height': height }">
  <div data-bind="dxReportDesigner: $data"></div>
</div>`,
  styles: []
})
export class DxReportDesignerComponent extends DxReportingComponent<any> {
  koReportUrl = ko.observable();

  @ContentChild(DxReportDesignerRequestOptionsComponent)
  requestOptions: DxReportDesignerRequestOptionsComponent;

  @ContentChild(DxReportDesignerCallbacksComponent)
  callbacksComponent: DxReportDesignerCallbacksComponent;
  
  constructor(renderer: Renderer2, @Inject(PLATFORM_ID) platformId: Object) { 
    super(renderer, platformId);
  }
  getCallbacks() {
    return this.callbacksComponent;
  }
  getKoBinding(options: any) {
    return new DxReportDesigner.Reporting.Designer.JSReportDesignerBinding(options, this.getEventRaiser());
  }
  getControlOptions() {
    return {
      allowMDI: this.allowMDI,
      reportUrl: this.koReportUrl,
      requestOptions: this.requestOptions,
      callbacks: { designer: {}, preview: {} }
    };
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.requestOptions = null;
    this.callbacksComponent = null;
    this.koReportUrl = null;
  }

  GetDesignerModel() {
    return this.bindingSender.GetDesignerModel();
  }

  GetPreviewModel() {
    return this.bindingSender.GetPreviewModel();
  }
  @Input() allowMDI?: boolean;

  @Input()
  get reportUrl() {
    return this.koReportUrl();
  }
  set reportUrl(reportUrl: string) {
    this.koReportUrl(reportUrl);
  }
}
