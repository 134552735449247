import { Component, Input, Renderer2, ContentChild, Inject, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import * as ko from 'knockout';
import DxReportViewer from "devexpress-reporting/dx-webdocumentviewer";
import { DxReportingComponent } from '../dx-reporting-component';

import { DxReportViewerRequestOptionsComponent } from './dxrv-request-options';
import { DxReportViewerCallbacksComponent } from './dxrv-callbacks';

import { DxReportViewerMobileModeSettingsComponent } from './dxrv-mobile-mode-settings.component';
import { DxReportViewerRemoteSettingsComponent } from './dxrv-remote-settings.component';
import { DxReportViewerTabPanelSettingsComponent } from './dxrv-tabpanel-settings.component';
import { DxReportViewerProgressBarSettingsComponent } from './dxrv-progressbar-settings.component';

@Component({
  selector: 'dx-report-viewer',
  encapsulation: ViewEncapsulation.None,
  template: `<div #control [ngClass]="cssClass" [ngStyle]="{'width': width, 'height': height }">
  <div data-bind="dxReportViewer: $data"></div>
</div>`,
  styles: []
})
export class DxReportViewerComponent extends DxReportingComponent<any> {
  koReportUrl = ko.observable();

  @ContentChild(DxReportViewerRequestOptionsComponent)
  requestOptionsComponent: DxReportViewerRequestOptionsComponent;

  @ContentChild(DxReportViewerCallbacksComponent)
  callbacksComponent: DxReportViewerCallbacksComponent;

  @ContentChild(DxReportViewerMobileModeSettingsComponent)
  mobileModeSettingsComponent: DxReportViewerMobileModeSettingsComponent;

  @ContentChild(DxReportViewerRemoteSettingsComponent)
  remoteSettingsComponent: DxReportViewerRemoteSettingsComponent;

  @ContentChild(DxReportViewerTabPanelSettingsComponent)
  tabPanelSettingsComponent: DxReportViewerTabPanelSettingsComponent;

  @ContentChild(DxReportViewerProgressBarSettingsComponent)
  progressBarSettingsComponent: DxReportViewerProgressBarSettingsComponent;

  constructor(renderer: Renderer2, @Inject(PLATFORM_ID) platformId: Object) {
    super(renderer, platformId);
  }

  getKoBinding(options: any) {
    return new DxReportViewer.Reporting.Viewer.JSReportViewerBinding(options, this.getEventRaiser());
  }
  getCallbacks(): any {
    return this.callbacksComponent;
  }
  getControlOptions() {
    return {
      rtl: this.rtl,
      isMobile: this.isMobile,
      reportUrl: this.koReportUrl,
      requestOptions: this.requestOptionsComponent,
      mobileModeSettings: this.mobileModeSettingsComponent,
      remoteSettings: this.remoteSettingsComponent,
      tabPanelSettings: this.tabPanelSettingsComponent,
      progressBarSettings: this.progressBarSettingsComponent,
      callbacks: {}
    };
  }

  GetPreviewModel() {
    return this.bindingSender.GetPreviewModel();
  }

  @Input() isMobile: boolean;

  @Input()
  get reportUrl() {
    return this.koReportUrl();
  }
  set reportUrl(reportUrl: string) {
    this.koReportUrl(reportUrl);
  }
}
