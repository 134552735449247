import { NgModule } from '@angular/core';
import { DxReportViewerCallbacksComponent } from './dx-report-viewer/dxrv-callbacks';
import { DxReportViewerRequestOptionsComponent } from './dx-report-viewer/dxrv-request-options';
import { DxReportViewerComponent } from './dx-report-viewer/dx-report-viewer.component';
import { DxReportViewerMobileModeSettingsComponent } from './dx-report-viewer/dxrv-mobile-mode-settings.component';
import { DxReportViewerRemoteSettingsComponent } from './dx-report-viewer/dxrv-remote-settings.component';
import { DxReportViewerTabPanelSettingsComponent } from './dx-report-viewer/dxrv-tabpanel-settings.component';
import { CommonModule } from '@angular/common';
import { DxReportViewerProgressBarSettingsComponent } from './dx-report-viewer/dxrv-progressbar-settings.component';

@NgModule({
  id: "DxReportViewerModule",
  imports: [ CommonModule ],
  declarations: [
    DxReportViewerComponent, 
    DxReportViewerCallbacksComponent,
    DxReportViewerMobileModeSettingsComponent,
    DxReportViewerRemoteSettingsComponent,
    DxReportViewerRequestOptionsComponent,
    DxReportViewerProgressBarSettingsComponent,
    DxReportViewerTabPanelSettingsComponent
  ],
  exports: [
    DxReportViewerComponent, 
    DxReportViewerCallbacksComponent,
    DxReportViewerMobileModeSettingsComponent,
    DxReportViewerRemoteSettingsComponent,
    DxReportViewerRequestOptionsComponent,
    DxReportViewerProgressBarSettingsComponent,
    DxReportViewerTabPanelSettingsComponent
  ]
})
export class DxReportViewerModule { }
