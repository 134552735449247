import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxReportDesignerRequestOptionsComponent } from './dx-report-designer/dxrd-request-options';
import { DxReportDesignerComponent } from './dx-report-designer/dx-report-designer.component';
import { DxReportDesignerCallbacksComponent } from './dx-report-designer/dxrd-callbacks';

@NgModule({
  id: "DxReportDesignerModule",
  imports: [ CommonModule ],
  declarations: [
    DxReportDesignerCallbacksComponent,
    DxReportDesignerRequestOptionsComponent,
    DxReportDesignerComponent
  ],
  exports: [
    DxReportDesignerCallbacksComponent,
    DxReportDesignerRequestOptionsComponent,
    DxReportDesignerComponent
  ]
})
export class DxReportDesignerModule { }
