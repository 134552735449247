import { AfterViewInit, Renderer2, ElementRef, ViewChild, Input, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import  * as ko from "knockout";

export abstract class DxReportingComponent<T> implements AfterViewInit, OnDestroy {
  private _koBinding;
  bindingSender: any;
  callbacks: any;
  @ViewChild("control")
  control: ElementRef;
  
  constructor(private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: Object) { }
  abstract getControlOptions(): T;
  abstract getCallbacks(): any;
  abstract getKoBinding(options: T): any;
  
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.callbacks = this.getCallbacks();
      const options = this.getControlOptions();
      this._koBinding = this.getKoBinding(options);
      this.bindingSender = this._koBinding.sender;
      this._koBinding.applyBindings(this.control.nativeElement);
    }
  }
  ngOnDestroy() {
    ko.cleanNode(this.control.nativeElement);
    this._koBinding = null;
    this.bindingSender = null;
    this.callbacks = null;
    this.control = null;
  }

  getEventRaiser() {
    var component = this;
    return (eventName, args?) => component.callbacks && component.callbacks[eventName] && component.callbacks[eventName].emit({ sender: component.bindingSender, args: args, component: component });
  }

  @Input() height: string = '800px';
  @Input() width: string = '100%';
  @Input() cssClass: string = '';
  @Input() rtl: boolean;
}
