import { Component, Input } from '@angular/core';

@Component({
  template: '',
  selector: 'dxrv-mobile-mode-settings'
})
export class DxReportViewerMobileModeSettingsComponent {
    @Input() readerMode: boolean;
    @Input() animationEnabled: boolean;
}
