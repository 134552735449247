import { Component, Input } from '@angular/core';

@Component({
  template: '',
  selector: 'dxrv-request-options'
})
export class DxReportViewerRequestOptionsComponent {
  @Input() invokeAction: string = "/DXXRDV";
  @Input() getLocalizationAction?: string;
  @Input() host: string;
}
