import { Component, Input } from '@angular/core';

@Component({
  template: '',
  selector: 'dxrv-remote-settings'
})
export class DxReportViewerRemoteSettingsComponent {
    @Input() authToken: string;
    @Input() serverUri: string;
}
