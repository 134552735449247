import { Component, Input } from '@angular/core';

@Component({
  template: '',
  selector: 'dxrd-request-options'
})
export class DxReportDesignerRequestOptionsComponent {
  @Input() getDesignerModelAction?: string;
  @Input() getLocalizationAction?: string;
  @Input() host: string;
}
