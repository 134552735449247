import { Component, Input } from '@angular/core';

@Component({
  template: '',
  selector: 'dxrv-tabpanel-settings'
})
export class DxReportViewerTabPanelSettingsComponent {
    @Input() position: string;
    @Input() width: number;
}
