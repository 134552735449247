import { Component, Input } from '@angular/core';

@Component({
  template: '',
  selector: 'dxrv-progressbar-settings'
})
export class DxReportViewerProgressBarSettingsComponent {
    @Input() position: string;
    @Input() keepOnVisibleArea: boolean;
}
